.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    border-radius:20%;
    border: 1.8px solid var(--orange);
}
.headermenu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}
.headermenu>li:hover{
    color:var(--orange);
    cursor: pointer;
}
@media screen and (max-width: 768px){
.header > :nth-child(2)
  {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
.headermenu
  {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}